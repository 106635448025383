import consumer from "./consumer"

function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a target="_blank" href="' + url + '">' + url + '</a>';
  })
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

function msg_content(data){
  var content = data['content'] != null ? urlify(data['content']) : '';
  if(data['file'] != null)
    content = `<span>${content}</span><p class="dosya-ek"><a class="dosya" target="blank" href="${data['file']}">${data['file'].split('/').pop()}</a></p>`
  else if(data['bid'])
    content = `<p><b>Ürün Adı:&nbsp;</b>${data['bid']['code']}<br><b>Adet:&nbsp;</b>${data['bid']['quantity']}<br><b>Teklif Tutarı:&nbsp;</b>${data['bid']['price']} ₺<br><b>Geçerlilik Tarihi:&nbsp;</b><time datetime="${data['bid']['validity']}">${data['bid']['validity']}</time><br><small>${data['bid']['description']}</small><br><a class="btn btn-xs btn-sm btn-light" rel="nofollow" data-method="post" href="/baskets/add_with_bid/${data['bid']['id']}">Siparişe Dönüştür</a></p>`;
  return '<li class="usr'+data['sender_id']+'"><div class="message-data"><span class="message-data-name">' + data['name'] + '&nbsp;, '+data['time']+'&nbsp;</span><i class="fa fa-circle me"></i></div><div class="message my-message">'+ content +'</div></li>';
}

if ($(".username").length > 0 && (window.location.pathname.includes('/hesap/chat') || window.location.pathname.includes('/hesap/yeni-is'))) {
  var chid = $(".username").data('id');
  consumer.subscriptions.create({
        channel: "RoomChannel",
        room_id: chid
      }, {
    connected() {
      // Called when the subscription is ready for use on the server
      $(".chat_await").hide();
      $(".chat_form").fadeIn();
      //var chid = $(".username").data('id');
      $(".chat-history").scrollTop($(".chat-history")[0].scrollHeight);
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // console.log('Veri alındı: ', data);
      // var message = '<li class="usr'+data['sender_id']+'"><div class="message-data"><span class="message-data-time">' + data['date'] + '&nbsp;&nbsp;</span><span class="message-data-name">' + data['name'] + '&nbsp;&nbsp;</span><i class="fa fa-circle me"></i></div><div class="message my-message">'+ content +'</div></li>';
      $("#conversation_" + data['room_id']).append(msg_content(data));
      $(".chat-history").scrollTop($(".chat-history")[0].scrollHeight);
    }
  });
}else if (window.location.pathname.includes('/admin') && !window.location.pathname.includes('/admin/chat')) {
  consumer.subscriptions.create({
        channel: "RoomChannel"
      }, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log('General Room Channel');
      
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      if(data['is_customer'])
        playSound('plucky');
    }
  });
}

